import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Img, { FixedObject } from 'gatsby-image';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles(theme => ({
  person: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
    minHeight: '216px',
  },
  personInfo: {
    padding: `${theme.spacing(5)}px 0`,
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(1)}px 0`,
    },
  },
  personWithAdditionalInfo: {
    padding: 0,
  },
  personInfoItem: {
    height: theme.spacing(3),
    paddingBottom: 0,
    paddingTop: 0,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
  normalText: {
    fontWeight: 'normal',
  },
  emailLink: {
    textDecoration: 'none',
  },
}));

type Person = {
  name: string;
  title: string;
  email: string;
  phone: string;
  imageIdentifier: string;
};

type PlaceholderImage = { childImageSharp: { fixed: FixedObject | FixedObject[] | undefined } };

type Images = Array<{ node: { base: string; childImageSharp: { fixed: FixedObject | FixedObject[] | undefined } } }>;

export type PersonComponentProps = { person: Person; placeholderImage: PlaceholderImage; images: Images };
export const PersonComponent: React.FunctionComponent<PersonComponentProps> = ({
  person,
  placeholderImage,
  images,
}) => {
  const classes = useStyles();
  const { name, title, email, phone, imageIdentifier } = person;
  const image = images.find(n => n.node.base.split('.png')[0] === imageIdentifier);
  const hasAdditionalInfo = email == null && phone == null ? false : true;

  const infoItem = (property: string, bold: boolean, isEmail: boolean) => (
    <ListItem className={classes.personInfoItem} key={name + '-' + property}>
      <Typography variant="body2" className={bold ? classes.boldText : classes.normalText}>
        {isEmail ? (
          <a className={classes.emailLink} href={`mailto:${property}`}>
            {property}
          </a>
        ) : (
          property
        )}
      </Typography>
    </ListItem>
  );

  return (
    <Grid item container xs={12} md={6} className={classes.person}>
      <Grid item xs={12} sm={4}>
        <Img fixed={image ? image.node.childImageSharp.fixed : placeholderImage.childImageSharp.fixed} />
      </Grid>
      <Grid item xs={12} sm={8} className={hasAdditionalInfo ? classes.personWithAdditionalInfo : classes.personInfo}>
        <List>
          {name && infoItem(name, true, false)}
          {title && infoItem(title, false, false)}
          <br />
          {email && infoItem(email, false, true)}
          {phone && infoItem(phone, false, false)}
        </List>
      </Grid>
    </Grid>
  );
};

export default PersonComponent;
