import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Person } from './';
import { BodyText, Header } from '../elements';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(12),
  },
  contactPerson: {
    fontWeight: 'normal',
    fontSize: theme.spacing(4.25),
    lineHeight: `${theme.spacing(4.5)}px`,
    color: theme.palette.treetDarkGray.main,
    marginTop: theme.spacing(8),
  },
  contactPersonName: {
    fontSize: theme.spacing(2.5),
    lineHeight: `${theme.spacing(2.75)}px`,
    color: theme.palette.treetDarkGray.main,
    paddingTop: theme.spacing(15),
  },
  contactDetail: {
    fontSize: theme.spacing(2.5),
    lineHeight: `${theme.spacing(2.75)}px`,
    color: theme.palette.treetDarkGray.main,
    paddingTop: theme.spacing(2),
  },
  linkArrow: {
    float: 'left',
    color: 'rgba(0, 0, 0, 0.87);',
  },
  ourStory: {
    marginTop: theme.spacing(8),
  },
  ourStoryHeading: {
    fontSize: theme.spacing(5.875),
    lineHeight: `${theme.spacing(7)}px`,
    color: theme.palette.treetDarkGray.main,
    marginBottom: theme.spacing(4),
  },
  ourStoryTextWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '800px',
      minHeight: '342px',
    },
    fontFamily: 'Cera Round Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: theme.spacing(2.25),
    lineHeight: `${theme.spacing(4)}px`,
    letterSpacing: '0.5px',
    color: '#000000',
  },
  whoAreWe: {
    marginTop: theme.spacing(8),
  },
  whoAreWeRow: {
    padding: `${theme.spacing(4)}px 0`,
  },
  whoAreWeHeading: {
    fontSize: theme.spacing(5.875),
    lineHeight: `${theme.spacing(7)}px`,
    color: theme.palette.treetDarkGray.main,
    marginBottom: theme.spacing(8),
  },
  ourOfficesHeading: {
    fontSize: theme.spacing(5.875),
    lineHeight: `${theme.spacing(7)}px`,
    color: theme.palette.treetDarkGray.main,
  },
  feedback: {
    paddingTop: theme.spacing(8),
  },
  feedbackHeading: {
    fontSize: theme.spacing(5.875),
    lineHeight: `${theme.spacing(7)}px`,
    color: theme.palette.treetDarkGray.main,
    marginBottom: theme.spacing(2),
  },
  customerSupport: {
    paddingTop: theme.spacing(8),
  },
  customerSupportHeading: {
    fontSize: theme.spacing(5.875),
    lineHeight: `${theme.spacing(7)}px`,
    color: theme.palette.treetDarkGray.main,
    marginBottom: theme.spacing(2),
  },
  emailLink: {
    textDecoration: 'none',
  },
  headerContainer: {
    marginBottom: theme.spacing(3),
  },
}));

type Person = { name: string; title: string; email: string; phone: string; imageIdentifier: string };
type Office = { city: string; address: string; postalCode: string };

const Contact: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              personnel {
                name
                title
                email
                phone
                imageIdentifier
              }
              offices {
                city
                address
                postalCode
              }
            }
          }
          images: allFile(filter: { relativeDirectory: { eq: "personnel" } }) {
            edges {
              node {
                base
                childImageSharp {
                  fixed(width: 145, height: 145) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          placeholderImage: file(relativePath: { eq: "personnel/placeholder.png" }) {
            childImageSharp {
              fixed(width: 145, height: 145) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Container className={classes.container} maxWidth="lg">
            <section id="ourStory" className={classes.ourStory}>
              <Grid container>
                <Grid className={classes.headerContainer} item xs={12}>
                  <Header variant="h1" styling="dark" center={false}>
                    Tarinamme
                  </Header>
                </Grid>
                <Grid item container xs={12}>
                  <div className={classes.ourStoryTextWrapper}>
                    <BodyText styling="dark" size="normal">
                      Treetin taustalla työskentelee monialainen joukko lääkehoidon, teknologian, designin ja viestinnän
                      ammattilaisia. Kuluttajat ansaitsevat palvelun, joka tekee henkilökohtaisesta lääkehoidosta ja
                      terveyden ylläpidosta helppoa, nopeaa, luotettavaa ja yksilöllistä - heidän arkeensa täydellisesti
                      sopivaa. Treetin kehitystyössä kaiken keskiössä on asiakas ja hänen lääkehoitonsa onnistuminen.
                    </BodyText>
                    <br />
                    <BodyText styling="dark" size="normal">
                      Treetin strategia nojaa kolmeen pääperiaatteeseen: Apteekkiasioinnin helpottaminen
                      lääketurvallisuutta vaarantamatta, apteekin farmaseuttisen osaamisen parempi hyödyntäminen osana
                      terveydenhuoltoa sekä tutkitun tiedon ja lääkehoitoon vaikuttavien osapuolien kokoaminen yhteen
                      asiakkaan tueksi.
                    </BodyText>
                  </div>
                </Grid>
              </Grid>
            </section>
            <section id="whoAreWe" className={classes.whoAreWe}>
              <Grid className={classes.headerContainer} item xs={12}>
                <Header variant="h2" styling="dark" center={false}>
                  Keitä olemme
                </Header>
              </Grid>
              <Grid container alignContent="center">
                {data.site.siteMetadata.personnel.map((person: Person) => (
                  <Person
                    person={person}
                    placeholderImage={data.placeholderImage}
                    images={data.images.edges}
                    key={`${person.name}-${person.title}`}
                  />
                ))}
              </Grid>
            </section>
            <section id="ourOffices">
              <Grid container>
                <Grid item xs={12}>
                  <Header variant="h2" styling="dark" center={false}>
                    Toimistomme
                  </Header>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {data.site.siteMetadata.offices.map((office: Office) => (
                      <Grid item xs={12} sm={6} key={office.address}>
                        <Header styling="dark" variant="h5" center={false}>
                          {office.city}
                        </Header>
                        <BodyText styling="dark" size="normal">
                          {office.address} <br /> {office.postalCode} <br /> {office.city}
                        </BodyText>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </section>
            <section id="feedback" className={classes.feedback}>
              <Grid container>
                <Grid item xs={12}>
                  <Header variant="h2" styling="dark" center={false}>
                    Palaute ja kehitysehdotukset
                  </Header>
                </Grid>
                <Grid item xs={12}>
                  <BodyText styling="dark" size="normal">
                    <a className={classes.emailLink} href="mailto:feedback@treet.fi">
                      feedback@treet.fi
                    </a>
                  </BodyText>
                </Grid>
              </Grid>
            </section>
            <section id="customerSupport" className={classes.customerSupport}>
              <Grid container>
                <Grid item xs={12}>
                  <Header variant="h2" styling="dark" center={false}>
                    Asiakaspalvelu
                  </Header>
                </Grid>
                <Grid item xs={12}>
                  <BodyText styling="dark" size="normal">
                    <a className={classes.emailLink} href="mailto:asiakaspalvelu@treet.fi">
                      asiakaspalvelu@treet.fi
                    </a>
                  </BodyText>
                </Grid>
              </Grid>
            </section>
          </Container>
        );
      }}
    />
  );
};

export default Contact;
