import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

import { Layout } from '../components';
import { Contact } from '../components/contact/';

const ContactPage: FunctionComponent = () => (
  <>
    <Helmet>
      <title>Treet</title>
    </Helmet>
    <Layout>
      <Contact />
    </Layout>
  </>
);

ContactPage.displayName = 'ContactPage';

export default ContactPage;
